import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';

import styles from './workshops.scss';

const Workshops = () => (
  <Layout>
    <Helmet>
      <body page-name="page-workshops" />
    </Helmet>
    <main>
      <h1>workshops</h1>
      <p>
        The sessions described below are the result of years of experimentation
        in teaching their respective topics. I take great joy in leading
        workshops that are interactive and engaging, taking a journey{' '}
        <em>with</em> the audience rather than lecturing <em>at</em> them,
        limiting use of static slides in favor of carefully curated live-coding
        exercises and explorations that maximize comprehension in the
        always-too-limited time available.
      </p>
      <p>
        Though my audiences typically have some prior background in programming,
        they span a large spectrum of experience in developing web applications
        and with software development in general. Interactive workshop formats
        provide the flexibility to dynamically tailor content to the needs of
        learners.
      </p>
      <section>
        <h2>
          Series: <em>Fundamentals of Web App Development</em>
        </h2>
        <p>
          This series explores broad fundamental concepts of web application
          development. In these interactive sessions we discuss relevant topics
          along with their best practices for <strong>security</strong>,{' '}
          <strong>accessibility</strong>, and <strong>performance</strong> while
          leveraging <strong>modern development tools</strong> to speed up and
          improve our workflow at every step of the development cycle.
        </p>
        <p>
          <em>
            The descriptions below appear as for sessions presented in Fall
            2018. Each session lasts approximately 2 hours, plus time for a
            brief intermission.
          </em>
        </p>

        <h3>
          Session&nbsp;1 &ndash; Web Architecture, Visual Styles, &amp; Layout
        </h3>
        <p>
          This session introduces the web ecosystem from a browser&rsquo;s
          (front-end) perspective. The first part introduces the web platform
          and related technologies, exploring the browser lifecycle and how
          different kinds of code are used together to build a complete web
          page.
        </p>
        <p>
          We discuss a few common paradigms of client-server interaction for web
          applications and the protocols over which they communicate.
        </p>
        <p>
          The latter part of this session focuses on understanding the
          fundamentals of CSS, the cascade, and how styles are applied to
          onscreen elements. We briefly introduce basic mechanics of page
          layout.
        </p>
        <h3>Session 2 &ndash; JavaScript, Part 1</h3>
        <p>
          This session covers the fundamentals of programming in JavaScript,
          assuming existing experience in at least one other programming
          language. This session is recommended for those whose projects involve
          either a front-end web component or are using a back-end system
          component written in JavaScript, such as Node.js. We cover the
          following topics:
        </p>

        <ul>
          <li>JS basics: syntax, variables, and control statements</li>
          <li>Data types and type coercion</li>
          <li>Functions, scopes, and closures</li>
          <li>Commonly-used JavaScript standard objects and utilities</li>
          <li>
            Using standard collection objects and methods to work with
            application data
          </li>
          <li>Connecting JavaScript and HTML through the DOM API</li>
          <li>
            Miscellaneous JS &ldquo;gotchas&rdquo; and common sources of
            confusion and headaches
          </li>
        </ul>

        <h3>Session 3 &ndash; Modern Application Layout in CSS</h3>
        <p>
          This session explores various approaches to designing resilient
          content and interface layouts that adapt to screens of any size. We
          compare the use of flow, flexbox, and grid layout, and media query
          mechanisms in CSS and discover the strengths and suitability of each
          for particular layout tasks.
        </p>

        <h3>Session 4 &ndash; JavaScript, Part 2</h3>
        <p>
          Building upon the fundamentals discussed in JavaScript Part 1, we
          explore
        </p>

        <ul>
          <li>Asynchronous JavaScript mechanisms in a single-threaded world</li>
          <li>AJAX and interacting with external APIs</li>
          <li>
            Object-oriented patterns in JS
            <ul>
              <li>Prototypical inheritance</li>
              <li>&quot;this&quot; and execution contexts</li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            Modular JavaScript
            <ul>
              <li>
                Splitting application source code into separate files, and how
                to get them all to the browser
              </li>
              <li>Using npm to manage third-party libraries</li>
            </ul>
          </li>
        </ul>

        <h3>Session 5 &ndash; Progressive Web Apps &amp; Modern Web APIs</h3>
        <p>
          In this session we take a high-level tour of recent advancements in
          web APIs and standards for browsers that enable developers to create
          rich web applications which have native-like features and can even
          work offline. We discuss technologies such as web workers, service
          workers, WebAssembly, push notifications, web payments, WebSockets,
          in-browser databases, and more.
        </p>
      </section>
      <section>
        <h2>
          Series: <em>Source Code Management with git</em>
        </h2>
        <p>
          Custom workshops on exploring the use of git, the distributed version
          control system indispensible to any person and team who touches code.
        </p>
        <p>
          We begin by discussing motivations for using version control and learn
          how git works at a fundamental level in order to develop an accurate
          and pragmatic mental model of how we can use git to work with code
          projects.
        </p>
        <p>
          After understanding how git works, we explore common workflows for
          tracking the history of code artefacts over time, first from the
          perspective of an individual and then from the perspective of a
          distributed team. We also troubleshoot how to fix common mistakes and
          otherwise recover from various missteps with git.
        </p>
        <p>
          We also explore additional communication, issue tracking, and project
          management features provided by popular git hosting services such as
          GitHub and GitLab that integrate tightly with your codebase.
        </p>
      </section>
    </main>
  </Layout>
);

export default Workshops;
